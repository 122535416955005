import Vue from "vue";
import VueRouter from "vue-router";

import { canNavigate } from "@/libs/acl/routeProtection";
import {
  isUserLoggedIn,
  getUserData,
  getHomeRouteForLoggedInUser,
} from "@/auth/utils";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/home",
      name: "home",
      meta: {
        action: "read",
        resource: "home",
      },
      component: () => import("@/views/Home.vue"),
    },
    {
      path: "/",
      name: "home",
      meta: {
        action: "read",
        resource: "home",
      },
      component: () => import("@/views/Home.vue"),
    },
    {
      path: "/settings",
      name: "settings",
      meta: {
        action: "read",
        resource: "settings",
      },
      component: () => import("@/views/Settings.vue"),
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/Login.vue"),
      meta: {
        layout: "full",
        resource: "Auth",
        redirectIfLoggedIn: true,
      },
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
        action: "hata",
        resource: "error-pages",
      },
    },
    {
      path: "/misc-not-authorized",
      name: "misc-not-authorized",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
        action: "hata",
        resource: "error-pages",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
    // Admin Sayfaları
    {
      path: "/Admin/ModulEkle",
      name: "ModulEkle",
      meta: {
        action: "read",
        resource: "ModulEkle",
      },
      component: () => import("@/views/adminPages/modulekle.vue"),
    },
    {
      path: "/Admin/SayfaEkle",
      name: "SayfaEkle",
      meta: {
        action: "read",
        resource: "SayfaEkle",
      },
      component: () => import("@/views/adminPages/sayfaEkle.vue"),
    },
    {
      path: "/Admin/RolEkle",
      name: "RolEkle",
      meta: {
        action: "read",
        resource: "RolEkle",
      },
      component: () => import("@/views/adminPages/rolekle.vue"),
    },
    {
      path: "/Admin/RolYetkiYonetimi",
      name: "RolYetkiYonetimi",
      meta: {
        action: "read",
        resource: "RolYetkiYonetimi",
      },
      component: () => import("@/views/adminPages/rolYetkiYonetimi.vue"),
    },

    //Stok
    {
      path: "/Stok/urunEkle",
      name: "UrunEkle",
      meta: {
        action: "read",
        resource: "UrunEkle",
      },
      component: () => import("@/views/stokPages/urunEkle.vue"),
    },
    {
      path: "/Stok/malKabul",
      name: "MalKabul",
      meta: {
        action: "read",
        resource: "MalKabul",
      },
      component: () => import("@/views/stokPages/malKabul.vue"),
    },
    {
      path: "/Stok/malKabulleri",
      name: "MalKabulleri",
      meta: {
        action: "read",
        resource: "MalKabulleri",
      },
      component: () => import("@/views/stokPages/malKabulleri.vue"),
    },
    {
      path: "/Stok/stoklar",
      name: "Stoklar",
      meta: {
        action: "read",
        resource: "Stoklar",
      },
      component: () => import("@/views/stokPages/stoklar.vue"),
    },
    //Magaza
    {
      path: "/magaza/satis",
      name: "Satis",
      meta: {
        action: "read",
        resource: "Satis",
      },
      component: () => import("@/views/magaza/satis.vue"),
    },
    {
      path: "/magaza/satislarim",
      name: "Satislarim",
      meta: {
        action: "read",
        resource: "Satislarim",
      },
      component: () => import("@/views/magaza/satislarim.vue"),
    },
    // Website
    {
      path: "/website/WebsiteProduct",
      name: "WebsiteProduct",
      meta: {
        action: "read",
        resource: "WebsiteProduct",
      },
      component: () => import("@/views/website/websiteProduct.vue"),
    },
    {
      path: "/website/Slider",
      name: "SliderPage",
      meta: {
        action: "read",
        resource: "SliderPage",
      },
      component: () => import("@/views/website/sliderPage.vue"),
    },
    {
      path: "/website/OurBusinessPartners",
      name: "OurBusinessPartners",
      meta: {
        action: "read",
        resource: "OurBusinessPartners",
      },
      component: () => import("@/views/website/OurBusinessPartners.vue"),
    },
  ],
});

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn();
  if (!canNavigate(to)) {
    if (!isLoggedIn) {
      localStorage.setItem("backPage", window.location.href);
      return next({ name: "login" });
    }
    return next({ name: "misc-not-authorized" });
  }

  if (to.meta.redirectIfLoggedIn !== undefined) {
    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
      const userData = getUserData();
      next(getHomeRouteForLoggedInUser(userData ? userData.role : null));
    }
  }

  return next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
