import Vue from "vue";

// axios
import axios from "axios";
import globalSettings from "../settings";

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: globalSettings.baseURL, //',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
});

Vue.prototype.$http = axiosIns;

export default axiosIns;
